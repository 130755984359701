import request from '@/utils/request'

// 加入阅读聊天室
export function joinQuestionRoom (questionId) {
  return request.get(`/user/chatroom/join/question/${questionId}`)
}

// 发送消息
export function sendMessage (data) {
  return request.post('/user/chatroom/message/send', data)
}

// 获取消息列表
export function fetchMessageList (data) {
  return request.post('/user/chatroom/message/list', data)
}
