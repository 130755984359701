<template>
  <div class="comment-window">
    <!-- 标题 -->
    <h3>讨论区</h3>
    <p>站长提示：请勿发布违法、违党、违心，散播黄、毒、赌、广告等言论，违、散者，封！<em>对于以上言论,请点击举报按钮。</em></p>
    <!-- 消息区域 -->
    <div class="message-window">
      <div class="message-list">
        <div class="no-more">
          <p v-if="commentData.list.length >= 100">站长提示：不能再加载更多消息啦～，浏览器会卡的 😢</p>
        </div>
        <EmptyTip tip="暂无评论，抢个沙发一起实时讨论～" v-if="commentData.list.length === 0"/>
        <template v-else v-for="(comment,index) in commentData.list">
          <!-- 其他用户发送的 -->
          <div v-if="userInfo == null || userInfo.id !== comment.user.id" :key="index" class="message other-message">
            <img :src="comment.user.avatar" class="avatar">
            <div>
              <div class="user">
                <span>{{comment.user.nickname}}</span>
                <UserMedals/>
              </div>
              <div class="content">
                <div v-html="comment.comment.content" class="rich-content"></div>
                <ul>
                  <li v-if="getEmojiId(comment.comment.content) != null">
                    <Button v-if="!isFavorite(comment.comment.content)" type="text" theme="info" size="mini" @click="favoriteEmoji(comment.comment.content)">收藏</Button>
                  </li>
                  <li><Button type="text" theme="info" size="mini">回复</Button></li>
                  <li><Button type="text" theme="info" size="mini">举报</Button></li>
                </ul>
              </div>
              <div class="info">
                {{comment.comment.location}}&nbsp;·&nbsp;{{comment.comment.createTime | timeText}}
              </div>
            </div>
          </div>
          <!-- 我发送的 -->
          <div v-else :key="index" class="message">
            <div>
              <div class="user">
                <UserMedals/>
                <span>我</span>
              </div>
              <div class="content">
                <ul>
                  <li v-if="getEmojiId(comment.comment.content) != null">
                    <Button v-if="!isFavorite(comment.comment.content)" type="text" theme="info" size="mini" @click="favoriteEmoji(comment.comment.content)">收藏</Button>
                  </li>
                  <li><Button type="text" theme="info" size="mini">撤回</Button></li>
                </ul>
                <div v-html="comment.comment.content" class="rich-content"></div>
              </div>
              <div class="info">
                {{comment.comment.location}}&nbsp;·&nbsp;{{comment.comment.createTime | timeText}}
              </div>
            </div>
            <img :src="comment.user.avatar" class="avatar">
          </div>
        </template>
      </div>
      <!-- 消息输入 -->
      <div class="message-input-wrap">
<!--        <div class="reply-content">-->
<!--          <p>-->
<!--            我觉得吧,你说的对。我觉得吧,你说的对。-->
<!--          </p>-->
<!--        </div>-->
        <div class="editor">
          <CommentInput ref="commentInput" height="55px" v-model="commentData.comment" @enter="sendMessage" @inputEmoji="sendMessage"/>
          <div class="opera">
            <Button type="solid" theme="function" size="big" @click="sendMessage">
              发送
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { favoriteEmoji } from '@/api/user'
import { fetchCommentList } from '@/api/comment'
import { sendMessage, joinQuestionRoom } from '@/api/chat'
import UserMedals from '@/components/common/usr/UserMedals'
import CommentInput from '@/components/common/CommentInput'
import EmptyTip from '@/components/common/EmptyTip'
import WSMixin from '@/components/mixins/WSMixin'
export default {
  name: 'CommentWindow',
  mixins: [WSMixin],
  components: { EmptyTip, CommentInput, UserMedals },
  props: {
    // 目标数据ID
    targetDataId: {
      type: Number,
      required: true
    },
    // 目标数据编码
    targetDataSerialNo: {
      type: String,
      required: true
    },
    // 目标数据类型
    targetDataType: {
      type: String,
      required: true
    }
  },
  watch: {
    targetDataType () {
      this.init()
    },
    targetDataSerialNo () {
      this.init()
    }
  },
  data () {
    return {
      // 房间编号
      roomNo: null,
      // 评论数据
      commentData: {
        isWorking: false,
        // 当前输入的评论
        comment: '',
        // 评论列表
        list: []
      }
    }
  },
  computed: {
    ...mapState(['userInfo']),
    favoriteEmojis () {
      return this.$store.state.userData.favoriteEmojis
    }
  },
  methods: {
    ...mapMutations(['addFavoriteEmojis']),
    // 加入聊天
    async join () {
      if (this.targetDataType === 'question') {
        await joinQuestionRoom(this.targetDataId)
          .then(data => {
            this.roomNo = data
          })
          .catch(e => {
            this.$tip.apiFailed(e)
          })
      }
    },
    // 获取消息列表
    fetchMessageList () {
      fetchCommentList({
        page: 1,
        capacity: 100,
        model: {
          targetDataId: this.targetDataId,
          targetDataType: this.targetDataType
        },
        sorts: [
          {
            property: 'userComment.CREATE_TIME',
            direction: 'DESC'
          }
        ]
      })
        .then(data => {
          this.commentData.list = data.records.reverse()
          this.__scrollToBottom()
        })
    },
    // 判断是否为表情
    getEmojiId (message) {
      if (message == null) {
        return null
      }
      if (message.indexOf('<img') === -1) {
        return null
      }
      if (message.indexOf('<img') !== message.lastIndexOf('<img')) {
        return null
      }
      if (message.indexOf('EMOJI_GIF') === -1) {
        return null
      }
      const matchs = /.*EMOJI_GIF#(\d+).*/.exec(message)
      if (matchs == null) {
        return null
      }
      return matchs[1]
    },
    // 发送消息
    async sendMessage () {
      if (this.commentData.isWorking) {
        return
      }
      if (this.commentData.comment === '<p><br></p>') {
        return
      }
      if (this.roomNo == null) {
        await this.join()
      }
      this.commentData.isWorking = true
      sendMessage({
        roomSerialNo: this.roomNo,
        message: this.commentData.comment
      })
        .then(() => {
          this.$refs.commentInput.clear()
          this.$refs.commentInput.focus()
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.commentData.isWorking = false
        })
    },
    // 收藏表情
    favoriteEmoji (message) {
      favoriteEmoji(this.getEmojiId(message))
        .then(emoji => {
          this.addFavoriteEmojis(emoji)
          this.$message.success('收藏成功')
        })
        .catch(() => {
          this.$message.error('收藏失败')
        })
    },
    // 判断表情是否已收藏
    isFavorite (message) {
      const emojiId = parseInt(this.getEmojiId(message))
      return this.favoriteEmojis.findIndex(emoji => emoji.id === emojiId) !== -1
    },
    // 滚动到最底部
    __scrollToBottom () {
      this.$nextTick(() => {
        // 滚动到最底部
        const domMessageList = document.querySelector('.message-list')
        domMessageList.scrollTop = domMessageList.scrollHeight
      })
    },
    // 处理WS消息数据
    __handleWsMessage (data) {
      this.commentData.list.push(data.data)
      this.__scrollToBottom()
    }
  },
  created () {
    this.$ws.sendMessage('CHANGE_PAGE', `CHAT_ROOM_${this.targetDataType}_${this.targetDataId}`)
    this.fetchMessageList()
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
@import "../../assets/style/emoji";
.comment-window {
  p {
    color: #999999;
    em {
      font-style: normal;
      color: $primary-color;
    }
  }
  // 消息窗口
  .message-window {
    // background: $background-color-second;
    border-radius: 10px;
    .message-list {
      max-height: 500px;
      padding: 0 20px 20px 20px;
      overflow-y: auto;
      // 没有更多消息
      .no-more {
        display: flex;
        justify-content: center;
        font-size: 12px;
        padding-top: 20px;
        p {
          margin: 0;
          padding-bottom: 20px;
          color: #ccc;
        }
      }
      // 消息
      .message {
        display: flex;
        margin-bottom: 30px;
        // 消息主体
        & > div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          // 用户昵称和勋章
          .user {
            display: flex;
            & > span {
              margin: 0 0 0 16px;
            }
          }
          // 内容
          .content {
            display: flex;
            align-items: flex-end;
            max-width: 70%;
            // 消息内容
            & > .rich-content {
              padding: 5px 0;
              background: #fff;
              border-radius: 10px;
            }
            // 操作
            ul {
              margin: 0 10px 0 0;
              padding: 0;
              display: flex;
              position: relative;
              top: 1px;
            }
          }
          // 附加信息
          .info {
            text-align: right;
            font-size: 12px;
            color: $font-color-third;
            margin-top: 5px;
          }
        }
        // 头像
        .avatar {
          flex-shrink: 0;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-left: 20px;
        }
        // 左侧消息
        &.other-message {
          // 消息主体
          & > div {
            align-items: flex-start;
            // 用户昵称和勋章
            .user {
              & > span {
                margin: 0 16px 0 0;
              }
            }
            // 内容
            .content {
              ul {
                margin: 0 0 0 10px;
              }
            }
          }
          // 头像
          .avatar {
            margin-left: 0;
            margin-right: 20px;
          }
        }
      }
    }
    // 消息输入
    .message-input-wrap {
      padding: 0 10px 10px 10px;
      // 回复内容
      .reply-content {
        padding: 5px 10px;
        background: $background-color-second;
        border-radius: 20px;
        p {
          display: inline-flex;
          max-width: 50%;
          padding: 5px 20px;
          background: $background-color-second;
          border-radius: 16px;
          font-size: 13px;
          margin: 0;
        }
      }
      // 编辑器
      .editor {
        width: 100%;
        box-sizing: border-box;
        background-color: $background-color-second;
        border-radius: 20px;
        /deep/ .w-e-scroll {
          // 输入框
          & > div {
            padding: 0 15px;
            background-color: $background-color-second;
          }
        }
        // 操作
        .opera {
          text-align: right;
          padding: 5px 10px;
        }
      }
    }
  }
}
</style>
