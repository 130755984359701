<template>
  <el-popover
    ref="popover"
    placement="top-start"
    width="500"
    trigger="click"
    popper-class="emoji-popper"
  >
    <el-tabs v-model="currentTab" tab-position="bottom">
      <el-tab-pane name="common">
        <!-- tab标题 -->
        <span slot="label"><Icon type="icon-xiaolian" size="small"/></span>
        <!-- 表情轮播 -->
        <el-carousel height="500px" trigger="click" :autoplay="false">
          <el-carousel-item v-for="common in commonEmojis" :key="common.key">
            <ul class="emoji-list">
              <li v-for="emoji in common.emojis" :key="emoji.id" @click="selectEmoji(emoji.uri, 'EMOJI#' + emoji.id, emoji.id)">
                <img :src="emoji.uri"/>
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </el-tab-pane>
      <!-- 用户收藏 -->
      <el-tab-pane name="favorite">
        <!-- tab标题 -->
        <span slot="label"><Icon type="icon-aixin" size="small"/></span>
        <NoLoginTip v-if="userInfo == null" tip="登录后才可以查看收藏的表情哦~" height="500px"/>
        <EmptyTip v-else-if="favoriteEmojis.length === 0" tip="您还没有收藏过表情哦~" height="500px"/>
        <!-- 表情轮播 -->
        <el-carousel v-else height="500px" trigger="click" :autoplay="false">
          <el-carousel-item v-for="favorite in favoriteEmojis" :key="favorite.key">
            <ul class="emoji-list network-emoji-list">
              <li v-for="emoji in favorite.emojis" :key="emoji.id" @click="selectEmoji('/api' + emoji.uri, 'EMOJI_GIF#' + emoji.id, emoji.id)">
                <img :src="'/api' + emoji.uri"/>
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
      </el-tab-pane>
    </el-tabs>
    <Button type="text" theme="info" size="mini" slot="reference"><Icon type="icon-xiaolian" size="small"/></Button>
  </el-popover>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import NoLoginTip from '@/components/common/NoLoginTip'
import BaseUser from '@/components/base/BaseUser'
import EmptyTip from '@/components/common/EmptyTip'

export default {
  name: 'Emoji',
  extends: BaseUser,
  components: { EmptyTip, NoLoginTip },
  data () {
    return {
      currentTab: 'common'
    }
  },
  computed: {
    commonEmojis () {
      const emojis = this.$store.state.basicData.commonEmojis
      const pageSize = Math.ceil(emojis.length / 72)
      const emojiGroup = []
      for (let i = 1; i <= pageSize; i++) {
        emojiGroup.push({
          key: 'common' + i,
          emojis: emojis.slice((i - 1) * 72, 72 * i)
        })
      }
      return emojiGroup
    },
    favoriteEmojis () {
      const emojis = this.$store.state.userData.favoriteEmojis
      const pageSize = Math.ceil(emojis.length / 25)
      const emojiGroup = []
      for (let i = 1; i <= pageSize; i++) {
        emojiGroup.push({
          key: 'favorite' + i,
          emojis: emojis.slice((i - 1) * 25, 25 * i)
        })
      }
      return emojiGroup
    }
  },
  methods: {
    ...mapMutations(['setFavoriteEmojis']),
    ...mapActions(['fetchEmojiList', 'fetchFavoriteEmojiList']),
    // 选择表情
    selectEmoji (uri, alt, id) {
      this.$emit('select', { uri, alt, id })
      this.$refs.popover.doClose()
    }
  },
  created () {
    this.fetchFavoriteEmojiList()
    this.fetchEmojiList()
  }
}
</script>

<style lang="scss">
@import "../../assets/style/variables";
.emoji-popper {
  user-select: none;
  padding: 0 !important;
  margin-bottom: 0 !important;
  .el-tabs__header {
    margin-top: 0 !important;
    padding: 5px 25px;
    border-top: 1px solid $font-color-forth;
  }
  // tab项
  .el-tabs__item {
    line-height: 30px;
    color: $font-color-third;
    font-weight: normal;
    font-size: 12px;
    padding: 5px 10px !important;
    margin-right: 10px;
    border-radius: 10px;
    &.is-active {
      background-color: $font-color-forth;
    }
    i.icon-aixin {
      color: $font-color-red;
    }
    i.icon-xiaolian {
      color: $primary-color;
    }
  }
  // tab线
  .el-tabs__nav-wrap::after {
    display: none;
  }
  // 选中线
  .el-tabs__active-bar {
    display: none;
  }
  // 切换按钮
  .el-carousel__arrow {
    display: none !important;
  }
  // 走马灯底部指示
  .el-carousel__indicator {
    .el-carousel__button {
      background-color: $font-color-third;
    }
  }
  // 表情列表
  .emoji-list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    max-height: 500px;
    margin: 0;
    overflow-y: auto;
    background-color: #fff;
    li {
      width: 12.5%;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      transition: all ease .15s;
      img {
        width: 25px;
      }
      &:hover {
        transition: all ease .15s;
        background-color: $font-color-forth;
      }
    }
    &.network-emoji-list {
      li {
        width: 20%;
        height: 92px;
        img {
          width: 80px;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
