import { render, staticRenderFns } from "./CommentWindow.vue?vue&type=template&id=26eafea9&scoped=true&"
import script from "./CommentWindow.vue?vue&type=script&lang=js&"
export * from "./CommentWindow.vue?vue&type=script&lang=js&"
import style0 from "./CommentWindow.vue?vue&type=style&index=0&id=26eafea9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26eafea9",
  null
  
)

export default component.exports