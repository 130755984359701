<template>
  <div class="comment-input">
    <ul class="toolbar">
      <li><Emoji @select="sendEmoji"/></li>
<!--      <li><Button type="text" theme="info" size="mini" @click="insertCode"><Icon type="icon-daima" size="small"/></Button></li>-->
    </ul>
    <Editor
      style="overflow-y: hidden;"
      :style="{ height }"
      :defaultConfig="editorConfig"
      mode="default"
      @onCreated="onCreated"
      @onChange="handleChange"
      @keydown.native="handleKeydown"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { Editor } from '@wangeditor/editor-for-vue'
import { useEmoji } from '@/api/user'
import Emoji from '@/components/common/Emoji'
import BaseComponent from '@/components/base/BaseComponent'
export default {
  name: 'CommentInput',
  extends: BaseComponent,
  components: { Emoji, Editor },
  props: {
    value: {
      type: String
    },
    // 高度
    height: {
      default: '120px'
    }
  },
  data () {
    const vm = this
    return {
      editor: null,
      editorConfig: {
        placeholder: '',
        // 内容悬浮内容处理
        hoverbarKeys: {
          text: {
            menuKeys: []
          },
          // 去掉图片处理
          image: {
            menuKeys: []
          }
        },
        MENU_CONF: {
          // 图片上传配置
          uploadImage: {
            // 文件参数名称
            fieldName: 'file',
            // 文件大小限制（5M）
            maxFileSize: 5 * 1024 * 1024,
            // 上传地址
            server: '/api/upload/comment/image',
            // 自定义插入图片
            customInsert (res, insertFn) {
              if (!res.success) {
                // TODO 提示上传失败
                return
              }
              // 插入图片
              insertFn(vm.imagePrefix + res.data.fileKey, 'EMOJI_GIF#' + res.data.businessData, '')
              // 聚焦输入
              setTimeout(() => {
                vm.focus()
              }, 300)
            }
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['topFavoriteEmoji']),
    onCreated (editor) {
      // 一定要用 Object.seal() ，否则会报错
      this.editor = Object.seal(editor)
    },
    // 内入输入后立即触发input实现，实现v-model
    handleChange () {
      this.$emit('input', this.editor.getHtml())
    },
    // 按键处理
    handleKeydown (event) {
      // 回车
      if (event.keyCode === 13) {
        event.stopPropagation()
        event.preventDefault()
        // 按住shift键时执行换行
        if (event.shiftKey) {
          this.editor.insertBreak()
          return
        }
        this.$emit('enter')
      }
    },
    // 插入代码
    insertCode () {
      // this.editor.setHtml(`${this.editor.getHtml()}<pre><code></code></pre>`)
    },
    // 发送表情
    sendEmoji ({ uri, alt, id }) {
      // 更新使用时间
      useEmoji(id).then().catch()
      // 添加图片至输入面板中（根据wangeditor的要求，无法添加class，但可以添加alt，通过alt来代替class以达到控制表情图片的目的）
      this.editor.setHtml(`${this.editor.getHtml()}<img src='${uri}' alt="${alt}"/>`)
      // 触发input实现
      this.$emit('input', this.editor.getHtml())
      // 如果是图片表情，则触发inputImageEmoji事件，方便聊天窗口执行消息发送
      if (alt.startsWith('EMOJI_GIF')) {
        this.$emit('inputEmoji')
      }
      setTimeout(() => {
        // 自动聚焦，此处需要延时才会生效
        this.focus()
      }, 300)
    },
    // 清空
    clear () {
      this.editor.clear()
    },
    // 聚焦
    focus () {
      this.editor.focus(true)
    }
  },
  // 组件销毁时，及时销毁编辑器
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/style/variables";
.comment-input {
  .toolbar {
    padding: 10px 10px 0 10px;
    margin: 0;
    display: flex;
    color: $font-color-third;
    li {
      margin-right: 10px;
    }
  }
  // 文本编辑
  /deep/ .w-e-text-container {
    p {
      margin: 0;
      font-size: 15px;
      line-height: 25px;
    }
    // 图片外壳
    .w-e-image-container {
      margin: 0;
      // 去掉图片拖拽改变大小的处理
      .w-e-image-dragger {
        display: none;
      }
      &:hover {
        box-shadow: none;
      }
      img {
        max-width: 200px;
      }
    }
    // 已选图片外壳
    .w-e-selected-image-container {
      overflow: initial;
    }
  }
}
</style>
